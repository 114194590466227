export default {
  breakpoints: ["40em", "52em", "64em"],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  colors: {
    text: "#000",
    background: "#fff",
    primary: "#211737",
    black: "#000000",
    error: "#E31818",
    grey: "#F4F4F4",
    darkGrey: "#CCCCCC",
  },
  fonts: {
    body: 'quasimoda, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: "optima, serif",
    monospace: "Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.3,
    heading: 1.125,
  },
  text: {
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
    },
  },
  styles: {
    body: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    h1: {
      fontFamily: "heading",
      fontSize: [72, null, 124, 150],
    },
    h2: {
      fontFamily: "heading",
      fontWeight: "heading",
      fontSize: [32, null, 38],
      mb: [3, null, 4],
    },
    h3: {
      fontFamily: "body",
      fontWeight: "heading",
      fontSize: [22, null, 24, 28],
    },
    p: {
      fontFamily: "body",
      fontSize: [17, null, 19, 21],
    },
    li: {
      fontFamily: "body",
      fontSize: [17, null, 19, 21],
    },
    strong: {
      fontFamily: "body",
      fontSize: [21, null, 19, 21],
    },
    dt: {
      fontFamily: "body",
      fontSize: [17, null, 19, 21],
    },
    dd: {
      fontFamily: "body",
      fontSize: [21, null, 19, 21],
    },
  },
}
