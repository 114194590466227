exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-design-system-tsx": () => import("./../../../src/pages/design-system.tsx" /* webpackChunkName: "component---src-pages-design-system-tsx" */),
  "component---src-pages-disclaimer-tsx": () => import("./../../../src/pages/disclaimer.tsx" /* webpackChunkName: "component---src-pages-disclaimer-tsx" */),
  "component---src-pages-eigen-werk-tsx": () => import("./../../../src/pages/eigen-werk.tsx" /* webpackChunkName: "component---src-pages-eigen-werk-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lessen-tsx": () => import("./../../../src/pages/lessen.tsx" /* webpackChunkName: "component---src-pages-lessen-tsx" */),
  "component---src-pages-over-ons-tsx": () => import("./../../../src/pages/over-ons.tsx" /* webpackChunkName: "component---src-pages-over-ons-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-templates-les-detail-tsx": () => import("./../../../src/templates/les-detail.tsx" /* webpackChunkName: "component---src-templates-les-detail-tsx" */)
}

